import theme from '@ifca-root/react-component/src/assets/theme';
import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';
import {
	Checkbox,
	FormControl,
	Grid,
	InputAdornment,
	makeStyles,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import './order.scss';
// import { useMultiPurposeFileUpload } from '@ifca-root/react-component/src/utils/hooks/useMultiPurposeFileUpload';

const styles = {
	inputRoot: {
		fontSize: 30,
	},
	labelRoot: {
		fontSize: 30,
		color: 'red',
		'&$labelFocused': {
			color: 'purple',
		},
	},
	labelFocused: {},
};

export const customLabel = (data: any, checked: boolean) => {
	return (
		<>
			{checked ? (
				<div className="xsTitle custom-label-checked">{data}</div>
			) : (
				<div className="xsTitle custom-label">{data}</div>
			)}
		</>
	);
};

export const customRadio = (
	//data
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	//extra
	control: any,
	optionErr: any,
	register: any,
	watch: any,
	clearErrors: any,
) => {
	return (
		<>
			<span>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={checkedRadioCondition(
										optionIndex,
										option,
										optionItem.optionItemID,
										//extra
										watch,
									)}
									value={JSON.stringify({
										itemID: optionItem?.optionItemID,
										price: optionItem?.price,
									})}
									name={optionItem?.optionItem?.name}
									checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
									icon={customLabel(optionItem?.optionItem?.name, false)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${optionIndex}].${option?.ID}[0]`}
						control={control}
						ref={register}
						// helperText="help"
						// error={true}
					/>
				))}
			</span>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}[0]`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

const checkedRadioCondition = (
	//data
	optionIndex: number,
	option: any,
	optionItemID: string,
	//extra
	watch: any,
) => {
	let optionItemIDandPrice: any = watch(
		`option[${optionIndex}].${option?.ID}[0]`,
	);
	if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.itemID) {
		return optionItemID === JSON.parse(optionItemIDandPrice)?.itemID;
	} else return false;
};

export const customCheckBox = (
	//data
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	//extra
	optionErr: any,
	register: any,
	setValue: any,
	unregister: any,
	watch: any,
	clearErrors: any,
) => {
	return (
		<>
			<div>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							// checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
							// icon={customLabel(optionItem?.optionItem?.name, false)}
							checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
							icon={customLabel(optionItem?.optionItem?.name, false)}
							onChange={e => {
								register(
									`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									setValue(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
										optionItem?.price,
									);
								} else {
									unregister(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
									);
								}
							}}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={optionItem?.optionItemID}
							name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
						/>
					</FormControl>
				))}
			</div>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					//
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const customSetMenuCheckBox = (
	//data
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	//extra
	optionErr: any,
	register: any,
	setValue: any,
	unregister: any,
	watch: any,
	clearErrors: any,
) => {
	return (
		<>
			<div>
				{setMenuOptionItem?.map(
					(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
						<FormControl component="fieldset">
							<Checkbox
								checkedIcon={customLabel(
									setMenuOptionItem?.menuItem?.name,
									true,
								)}
								icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
								onChange={e => {
									register(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
									);
									if (e.target.checked) {
										setValue(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
											{
												quantity: setMenuOptionItem?.quantity || 1,
												menuItemID: setMenuOptionItem?.menuItem?.ID,
											},
										);
									} else {
										unregister(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										);
									}
								}}
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								ref={register}
								value={setMenuOptionItem?.ID}
								name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
							/>
						</FormControl>
					),
				)}
			</div>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					//
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}`) ===
							undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const customSetMenuRadio = (
	//data
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	//extra
	control: any,
	optionErr: any,
	register: any,
	watch: any,
) => {
	return (
		<>
			<span>
				{setMenuOptionItem?.map(
					(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
						<Controller
							as={
								<RadioGroup>
									<Radio
										checked={checkedRadioCondition(
											setMenuOptionIndex,
											setMenuOption,
											setMenuOptionItem.ID,
											watch,
										)}
										value={JSON.stringify({
											itemID: setMenuOptionItem?.ID,
											quantity: setMenuOptionItem?.quantity || 1,
											menuItemID: setMenuOptionItem?.menuItem?.ID,
										})}
										name={setMenuOptionItem?.menuItem?.name}
										checkedIcon={customLabel(
											setMenuOptionItem?.menuItem?.name,
											true,
										)}
										icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
										ref={register}
									/>
								</RadioGroup>
							}
							name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
							control={control}
							ref={register}
						/>
					),
				)}
			</span>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`) ===
							undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

//OPEN DIALOG FOR STANDARD ORDER ITEM
//OPEN DIALOG FOR STANDARD ORDER ITEM
//OPEN DIALOG FOR STANDARD ORDER ITEM

const useStyles = makeStyles(() => ({
	formControlLabel: { fontSize: '15px', '& label': { fontSize: '0.6rem' } },
}));

export const MenuDialog = (props: any) => {
	const {
		checkMenuOptionError,
		clickButton,
		currMenu,
		handleSubmit,
		menuItemPatronPrice,
		mode,
		openMenu,
		quantity,
		selectedOrder,
		setAddOrder,
		setCurrMenu,
		setOpenMenu,
		setOptionErr,
		setQuantity,
		setTakeAwayStatus,
		weightName,
		//extra
		control,
		optionErr,
		register,
		setValue,
		unregister,
		watch,
		audioInput,
		setAudioInput,
		record,
		setRecord,
		setText,
		handleSingleRemark,
		clearErrors,
		errors,
		attachmentData,
		isSubmitting,
		setIsSubmitting,
		isDesktop,
		getRoundedQty,
		currencyCode = '',
		createOptionLoading,
	} = props;

	const classes = useStyles();

	// const getRoundedQty = qty1 => {
	// 	let outstandingQuantity =
	// 		currMenu?.menuItem?.dailyAvailability?.outstandingQty;
	// 	let roundedQty =
	// 		qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
	// 	return roundedQty;
	// };

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={openMenu}
				onClose={() => {
					setOpenMenu(false);
					setQuantity(1);
					setTakeAwayStatus(false);
					setOptionErr(false);
					setCurrMenu(null);
					setAudioInput('');
					setIsSubmitting(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{mode === 'takeAway'
												? `Takeaway ${selectedOrder?.docNo}`
												: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{currMenu?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(menuItemPatronPrice)}`}
									</div>
								</div>
							</div>
						),
						// children: (
						// 	<>
						// 		<ListItem
						// 			// style={{ backgroundColor: '#c7d6ff' }}
						// 			className="remove-padding"
						// 		>
						// 			<ListItemText
						// 				primary={
						// 					<>
						// 						<div
						// 							className="smTitle highlight-text"
						// 							// style={{ color: '#ff9800' }}
						// 						>
						// 							<span style={{ marginRight: '15px' }}>
						// 								{mode === 'takeAway'
						// 									? `Takeaway ${selectedOrder?.docNo}`
						// 									: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
						// 							</span>
						// 						</div>
						// 					</>
						// 				}
						// 				secondary={
						// 					<>
						// 						<span
						// 							className="highlight-text flex-space"
						// 							style={{ color: 'black' }}
						// 						>
						// 							{currMenu?.name}
						// 						</span>
						// 						<span
						// 							className="highlight-text"
						// 							style={{ color: 'black' }}
						// 						>
						// 							{`$${amtStr(menuItemPatronPrice)}`}
						// 						</span>
						// 					</>
						// 				}
						// 			/>
						// 		</ListItem>
						// 		<hr className="line-subheader" />
						// 	</>
						// ),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								{attachmentData?.attachmentListing?.map((el, index) => (
									<div className="img-container">
										<img src={el.fileURL} alt="" />
									</div>
								))}
							</MultipleItemsCarousel>
							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{currMenu?.description}
								</span>
								<hr className="line-description" />

								<div>
									{/* OPEN ITEM */}
									{/* OPEN ITEM */}

									{currMenu?.isOpenItem && (
										<div className="" style={{ marginBottom: '10px' }}>
											<span>
												<Controller
													as={TextField}
													fullWidth
													label="Menu Item"
													variant="standard"
													size="small"
													autoComplete="off"
													multiline={true}
													name="openItem"
													control={control}
													ref={register}
													required
													helperText={errors?.openItem?.message}
													error={errors?.openItem ? true : false}
												/>
											</span>
										</div>
									)}

									{/* OPEN PRICE */}
									{/* OPEN PRICE */}

									{(currMenu?.isOpenItem || currMenu?.isOpenPrice) && (
										<div className="" style={{ marginBottom: '20px' }}>
											<span>
												<Controller
													as={
														<NumberFormat
															customInput={TextField}
															fullWidth
															label="Price"
															autoComplete="off"
															isNumericString
															decimalScale={2}
															fixedDecimalScale
															style={{
																marginBottom: '0px',
																marginTop: '0px',
															}}
															margin="dense"
															required
															// ref={register}
															// control={control}
															allowNegative={false}
														/>
													}
													name="openPrice"
													control={control}
													ref={register}
													helperText={errors?.openPrice?.message}
													error={errors?.openPrice ? true : false}
												/>
											</span>
										</div>
									)}

									<Grid container style={{ marginBottom: '15px' }}>
										{!currMenu?.isByWeight ||
										(!currMenu?.isByWeight && currMenu?.isOpenItem) ? (
											// <QUANTITY>
											// <QUANTITY>
											<Grid item xs={7}>
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>
													{/* <span className="order-quantity"> {quantity} </span> */}
													<Controller
														render={({ onChange, onBlur, value }) => (
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																variant="standard"
																thousandSeparator
																fullWidth={false}
																margin="dense"
																className="quantity-input"
																InputProps={{
																	disableUnderline: true,
																}}
																onValueChange={(value: any) => {
																	setQuantity(getRoundedQty(value?.floatValue));
																	setValue(
																		'qty',
																		getRoundedQty(value?.floatValue),
																	);
																}}
																// isAllowed={values => {
																// 	const { formattedValue, floatValue } = values;
																// 	let a =
																// 		currMenu?.menuItem?.dailyAvailability
																// 			?.outstandingQty;
																// 	return (
																// 		formattedValue === '' ||
																// 		floatValue <=
																// 			currMenu?.menuItem?.dailyAvailability
																// 				?.outstandingQty
																// 	);
																// }}
																style={{
																	width: isDesktop ? '25%' : '45%',
																	borderRadius: '8px',
																	border: 'solid',
																	borderColor: 'orange',
																}}
																// variant="outlined"
																defaultValue={quantity}
																value={watch('qty')}
															/>
														)}
														name="qty"
														control={control}
														ref={register}
													/>
													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											</Grid>
										) : (
											// <WEIGHT>
											// <WEIGHT>
											<>
												<Grid
													item
													xs={7}
													className="mdDesc"
													style={{ alignSelf: 'center' }}
												>
													<span
														style={{ float: 'left', marginTop: '12px' }}
													>{`Weight `}</span>
													<Controller
														as={
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																fullWidth={false}
																decimalScale={1}
																fixedDecimalScale
																margin="dense"
																// ref={register}
																// control={control}
																defaultValue={1}
																className="weight-input"
																// allowNegative
																isAllowed={value => {
																	const { floatValue } = value;
																	return floatValue >= 0;
																}}
															/>
														}
														name="getWeight"
														size="small"
														fullWidth={false}
														// label="Weight"
														style={{ marginLeft: '8px', width: '50%' }}
														variant="outlined"
														control={control}
														ref={register}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{` ${weightName?.code}`}
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											</>
										)}
										{mode !== 'takeAway' && (
											<Grid
												item
												xs={5}
												style={{
													float: 'right',
													alignSelf: 'center',
													textAlign: 'end',
												}}
											>
												<Checkbox
													value={currMenu?.ID}
													onChange={e =>
														e.target.checked
															? setTakeAwayStatus(true)
															: setTakeAwayStatus(false)
													}
													color="primary"
													style={{ padding: '5px' }}
													inputProps={{
														'aria-label': 'secondary checkbox',
													}}
													ref={register}
												/>

												<span className="mdDesc">Take Away</span>
											</Grid>
										)}
									</Grid>

									{currMenu?.menuItem?.menuItemOptions?.map((v, i) => (
										<>
											<div
												className="option-radio"
												style={{ paddingTop: '10px' }}
											>
												<div className="mdDesc" key={v?.menuItemID}>
													{v?.maxSelections === 1
														? `${v?.option?.name}* (Require 1 option)`
														: v?.maxSelections > 1
														? `${v?.option?.name}* (Choose at most ${v?.maxSelections} option)`
														: `${v?.option?.name}`}
												</div>
												{v?.maxSelections === 1
													? customRadio(
															v?.option?.optionItem,
															v?.option,
															i,
															v?.menuItemOptionItem,
															//extra
															control,
															optionErr,
															register,
															watch,
															clearErrors,
													  )
													: customCheckBox(
															v?.option?.optionItem,
															v?.option,
															i,
															v?.menuItemOptionItem,
															//extra
															optionErr,
															register,
															setValue,
															unregister,
															watch,
															clearErrors,
													  )}
											</div>
											{/* <div>
                                                <TextField
                                                    InputProps={{ disableUnderline: true }}
                                                    disabled
                                                    style={{ marginTop: '-35px' }}
                                                    helperText={
                                                        optionErr ? 'Must Select atleast 1 option' : null
                                                    }
                                                    error={optionErr}
                                                />
                                            </div> */}
										</>
									))}
									<div className="mdDesc" style={{ padding: '10px 0px' }}>
										{'Special Request'}
									</div>
									<>
										{
											<VoiceTextField
												name="specialRequest"
												mounted={true}
												label="We will try our best to fulfill your request..."
												// InputLabelProps={{ style: { fontSize: '40px' } }}
												required
												value={audioInput}
												record={record}
												setRecord={setRecord}
												setValue={setValue}
												audioInput={audioInput}
												setAudioInput={setAudioInput}
												openDialog={openMenu}
												customOnchange
												register={register}
												control={control}
												clearErrors={clearErrors}
												handleCustomOnChange={e => {
													setAudioInput(e.target.value);
												}}
											/>
										}
									</>
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenMenu(false);
										setQuantity(1);
										setTakeAwayStatus(false);
										setOptionErr(false);
										setCurrMenu(null);
										setAudioInput('');
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Add',
								props: {
									onClick: e => {
										setIsSubmitting(false);
										if (quantity !== 0) {
											if (currMenu?.menuItem?.menuItemOptions?.length > 0) {
												if (!checkMenuOptionError()?.includes(false)) {
													!isSubmitting && handleSubmit(setAddOrder)();
												} else {
													setOptionErr(true);
												}
											} else {
												!isSubmitting && handleSubmit(setAddOrder)();
											}
										}
										e.stopPropagation();
										setAudioInput('');
										// quantity !== 0 ? handleSubmit(setAddOrder)() : null;
									},
									color:
										// quantity !== 0 || currMenu?.isByWeight
										// currMenu?.isByWeight || quantity !== 0
										createOptionLoading ? 'inherit' : 'primary',
									disabled: createOptionLoading ? true : false,
									// disabled: isSubmitting,
									variant: 'contained',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SetMenuDialog = (props: any) => {
	const {
		addSetMenuOrderItem,
		clickButton,
		currMenu,
		handleSubmit,
		menuItemPatronPrice,
		mode,
		openSetMenuDialog,
		quantity,
		selectedOrder,
		setCurrMenu,
		setOpenSetMenuDialog,
		setOptionErr,
		setQuantity,
		setTakeAwayStatus,
		//extra
		control,
		optionErr,
		register,
		setValue,
		unregister,
		watch,
		attachmentData,
		clearErrors,
		errors,
		checkSetMenuOptionError,
		isSubmitting,
		setIsSubmitting,
		isDesktop,
		getRoundedQty,
		currencyCode,
		createSetMenuOptionLoading,
	} = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={openSetMenuDialog}
				onClose={() => {
					setOpenSetMenuDialog(false);
					setQuantity(1);
					setTakeAwayStatus(false);
					setOptionErr(false);
					setCurrMenu(null);
					setIsSubmitting(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{mode === 'takeAway'
												? `Takeaway ${selectedOrder?.docNo}`
												: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{currMenu?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(menuItemPatronPrice)}`}
									</div>
								</div>
							</div>
						),
						// children: (
						// 	<>
						// 		<ListItem
						// 			// style={{ backgroundColor: '#c7d6ff' }}
						// 			className="remove-padding"
						// 		>
						// 			<ListItemText
						// 				primary={
						// 					<>
						// 						<div
						// 							className="smTitle highlight-text"
						// 							// style={{ color: '#ff9800' }}
						// 						>
						// 							<span style={{ marginRight: '15px' }}>
						// 								{mode === 'takeAway'
						// 									? `Takeaway ${selectedOrder?.docNo}`
						// 									: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
						// 							</span>
						// 						</div>
						// 					</>
						// 				}
						// 				secondary={
						// 					<>
						// 						<span
						// 							className="highlight-text flex-space"
						// 							style={{ color: 'black' }}
						// 						>
						// 							{currMenu?.name}
						// 						</span>
						// 						<span
						// 							className="highlight-text"
						// 							style={{ color: 'black' }}
						// 						>
						// 							{`$${amtStr(menuItemPatronPrice)}`}
						// 						</span>
						// 					</>
						// 				}
						// 			/>
						// 		</ListItem>
						// 		<hr className="line-subheader" />
						// 	</>
						// ),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								{attachmentData?.attachmentListing?.map((el, index) => (
									<div className="img-container">
										<img src={el.fileURL} alt="" />
									</div>
								))}
							</MultipleItemsCarousel>
							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{currMenu?.description}
								</span>
								<hr className="line-description" />

								<div>
									{/* QUANTITY */}
									{/* QUANTITY */}

									{(!currMenu?.isByWeight ||
										(!currMenu?.isByWeight && currMenu?.isOpenItem)) && (
										<span>
											<IndeterminateCheckBoxIcon
												className="order-minus"
												onClick={() => clickButton('reduce')}
											/>
											{/* <span className="order-quantity"> {quantity} </span> */}

											<Controller
												render={({ onChange, onBlur, value }) => (
													<NumberFormat
														customInput={TextField}
														autoComplete="off"
														isNumericString
														thousandSeparator
														fullWidth={false}
														margin="dense"
														className="quantity-input"
														variant="standard"
														InputProps={{
															disableUnderline: true,
														}}
														onValueChange={(value: any) => {
															setQuantity(getRoundedQty(value?.floatValue));
															setValue('qty', getRoundedQty(value?.floatValue));
														}}
														// isAllowed={values => {
														// 	const { formattedValue, floatValue } = values;
														// 	let a =
														// 		currMenu?.menuItem?.dailyAvailability
														// 			?.outstandingQty;
														// 	return (
														// 		formattedValue === '' ||
														// 		floatValue <=
														// 			currMenu?.menuItem?.dailyAvailability
														// 				?.outstandingQty
														// 	);
														// }}
														style={{
															width: isDesktop ? '25%' : '45%',
															borderRadius: '8px',
															border: 'solid',
															borderColor: 'orange',
														}}
														// variant="outlined"
														defaultValue={quantity}
														value={watch('qty')}
													/>
												)}
												name="qty"
												control={control}
												ref={register}
											/>
											<AddBox
												className="order-add"
												onClick={() => clickButton('add')}
											/>
										</span>
									)}
									{mode !== 'takeAway' && (
										<span
											style={{
												float: 'right',
												position: 'relative',
												paddingTop: '10px',
											}}
										>
											<Checkbox
												value={currMenu?.ID}
												onChange={e =>
													e.target.checked
														? setTakeAwayStatus(true)
														: setTakeAwayStatus(false)
												}
												color="primary"
												style={{ padding: '5px' }}
												inputProps={{
													'aria-label': 'secondary checkbox',
												}}
												ref={register}
											/>

											<span className="mdDesc">Take Away</span>
										</span>
									)}

									<div style={{ padding: '10px 0px' }}>
										<div
											style={{ paddingBottom: '5px' }}
											className="desc"
										>{`Included in ${currMenu?.name}`}</div>
										{currMenu?.menuItem?.setMenuOption
											?.filter(option => option?.maxSelections === 0)[0]
											?.setMenuOptionItem?.map((optionItem, i) => (
												<div
													style={{ paddingBottom: '5px' }}
													className="xsTitle"
												>{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}</div>
											))}
									</div>
									{currMenu?.menuItem?.setMenuOption
										?.filter(
											setMenuOption => setMenuOption?.maxSelections !== 0,
										)
										?.map((setMenuOption, i) => (
											<div className="option-radio">
												<div className="mdDesc" key={setMenuOption?.ID}>
													{setMenuOption?.maxSelections === 1
														? `${setMenuOption?.name}* (Require 1 option)`
														: setMenuOption?.maxSelections > 1
														? `${setMenuOption?.name}* (Choose at most ${setMenuOption?.maxSelections} option)`
														: `${setMenuOption?.name}`}
												</div>
												{setMenuOption?.maxSelections === 1
													? customSetMenuRadio(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															//extra
															control,
															optionErr,
															register,
															watch,
													  )
													: setMenuOption?.maxSelections > 1
													? customSetMenuCheckBox(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															//extra
															optionErr,
															register,
															setValue,
															unregister,
															watch,
															clearErrors,
													  )
													: null}
											</div>
										))}
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenSetMenuDialog(false);
										setQuantity(1);
										setTakeAwayStatus(false);
										setOptionErr(false);
										setCurrMenu(null);
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Add',
								props: {
									onClick: e => {
										setIsSubmitting(true);
										if (quantity !== 0) {
											if (currMenu?.menuItem?.setMenuOption?.length > 0) {
												if (
													!checkSetMenuOptionError()?.includes(false)
													// currMenu?.isByWeight &&
													// watch(`option`)?.length ===
													// 	currMenu?.menuItem?.setMenuOption?.length
												) {
													!isSubmitting && handleSubmit(addSetMenuOrderItem)();
												} else {
													setOptionErr(true);
												}
											} else {
												!isSubmitting && handleSubmit(addSetMenuOrderItem)();
											}
										}
										e.stopPropagation();
									},
									color: createSetMenuOptionLoading ? 'inherit' : 'primary',
									// color: quantity !== 0 ? 'primary' : 'inherit',
									variant: 'contained',
									type: 'submit',
									disabled: createSetMenuOptionLoading ? true : false,
									// disabled: isSubmitting,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
